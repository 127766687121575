import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';

// Components
import Title from '../components/text/title';
import ContentContainer from '../components/global/contentContainer';

const ContainerTheme = {
  backgroundColor: '#dcbeff',
};

const CheckoutStage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-bottom: 54px;
`;

const Stage = styled.p`
  font-size: 20px;
  color: ${props => (props.active ? 'black' : '#d27dff') || 'black'};

  margin: 0 30px 0 0;

  &:last-of-type {
    margin: 0;
  }
`;

const CartContainer = styled.div`
  margin: 0 27px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const ThankYouContainer = styled.div`
  max-width: 700px;
  width: 100%;
background-color: #fff;
  }
`;

const FormHeader = styled.div`
  background-color: #d27dff;
  color: #fff;

  font-size: 20px;
  text-align: center;

  padding: 17px;
`;

const ThankYouMessage = styled.div`
  font-size: 18px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  border-bottom: 2px solid #d27dff;
  border-left: 2px solid #d27dff;
  border-right: 2px solid #d27dff;

  padding: 18px;
  min-height: 120px;

  & p {
    margin: 0;
    width: 100%;
    max-width: 450px;
  }
`;

const ordercomplete = () => {
  return (
    <>
      <Helmet title={'Checkout'} />
      <Row css={ContainerTheme} justifyContent="center">
        <Col col={12}>
          <ContentContainer padding={'0 36px'}>
            <Title margin={'80px 0 36px 0'} text={'CHECKOUT'} case="inherit" />
          </ContentContainer>
        </Col>

        <Col col={12}>
          <ContentContainer padding={'0 36px'}>
            <CheckoutStage>
              <Stage>➀ Shopping Cart</Stage>
              <Stage active={true}>❷ Order Confirmed</Stage>
            </CheckoutStage>
          </ContentContainer>
        </Col>

        <Col col={12}>
          <ContentContainer padding={'0 36px 54px'}>
            <CartContainer>
              <ThankYouContainer>
                <FormHeader>THANK YOU</FormHeader>
                <ThankYouMessage>
                  <p>
                    We have processed your order and a receipt has been sent to
                    your email
                  </p>
                </ThankYouMessage>
              </ThankYouContainer>
            </CartContainer>
          </ContentContainer>
        </Col>
      </Row>
    </>
  );
};

export default ordercomplete;
